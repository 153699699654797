import React from 'react'
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
  } from "react-icons/fa";
  import { Link } from "react-router-dom";

const MotorInsuranceDetails = () => {
  return (
  <>
  
   
  <div className='service-area pd-top-120 pd-bottom-90'>
  <div className='container'>
    <div className='row'>
<div className='col-xl-12 col-lg-12 col-12 '>
        <div className='blog-details-page-content'>
          <div className='single-blog-inner mb-0'>
           <div className='details'>
<div className="row">
<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<h4 className='pt-5'> Motor Insurance </h4>
<p> Motor insurance, also known as auto insurance or car insurance, is a type of insurance 
policy designed to protect individuals and businesses from financial losses arising from 
accidents, theft, or other unforeseen events involving their motor vehicles. This insurance 
is typically required by law in most countries to operate a motor vehicle on public roads. </p>
<p>Here are some key aspects of motor insurance: </p>
</div>
<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<div className='thumb'>
<img src='assets/img/service/motor-insurance.jpg' alt='motor-insurance' />
</div>
</div>
</div>


<div  className='accordion accordion-inner style-2 accordion-icon-left mt-3 mb-4'
id='accordionExample'>
<div className="row">
<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<div className='accordion-item'>
<h2 className='accordion-header' id='headingOne'>
<button className='accordion-button' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
1.	Coverage Types: </button> </h2>
<div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
data-bs-parent='#accordionExample' >
<div className='accordion-body'> 
<ul>
<li><b>  Liability Coverage:</b> This covers the costs of injuries or property 
damage to other people in an accident for which you are at fault. </li>
<li><b> Collision Coverage:</b> This covers the cost of repairing or replacing your own vehicle 
if it's damaged in a collision with another vehicle or object. </li>
<li><b>  Comprehensive Coverage:</b> This covers damage to your vehicle that is not caused by a collision, 
such as theft, vandalism, natural disasters, or hitting an animal. </li>
<li><b>  Uninsured/Underinsured Motorist Coverage: </b> This protects you if you're in 
an accident with a driver who has no insurance or inadequate insurance. </li>
</ul>
</div>
</div>
</div>


<div className='accordion-item'>
<h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseThree' aria-expanded='false'
aria-controls='collapseThree'>
3.	Deductible:   </button> </h2>
<div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
This is the amount you have to pay out of pocket before your 
insurance coverage kicks in. Higher deductibles generally result in lower premiums.
</div>
</div>
</div>





<div className='accordion-item'>
<h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseFive' aria-expanded='false'
aria-controls='collapseFive'>
5.	Additional Coverage:  </button> </h2>
<div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive'
data-bs-parent='#accordionExample' >
<div className='accordion-body'> You can often add additional coverage options to your policy, 
such as rental car reimbursement, roadside assistance, and coverage for custom equipment.
</div>
</div>
</div>




<div className='accordion-item'>
<h2 className='accordion-header' id='headingSeven'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseSeven' aria-expanded='false'
aria-controls='collapseSeven'>
7.	Exclusions:  </button> </h2>
<div id='collapseSeven' className='accordion-collapse collapse' aria-labelledby='headingSeven'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
 Be aware of what is not covered by your policy. Common exclusions 
might include intentional damage, racing, or driving under the influence.
</div>
</div>
</div>


<div className='accordion-item'>
<h2 className='accordion-header' id='headingNine'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseNine' aria-expanded='false'
aria-controls='collapseNine'>
9.	Third-Party Liability: </button> </h2>
<div id='collapseNine' className='accordion-collapse collapse' aria-labelledby='headingNine'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
 In many places, a minimum level of liability 
coverage is mandatory to protect other parties in case you are at fault in an accident.
</div>
</div>
</div>
</div>


<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<div className='accordion-item'>
<h2 className='accordion-header' id='headingTwo'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseTwo' aria-expanded='false' aria-controls='collapseTwo'>
2.	Premium:  </button> </h2>
<div
id='collapseTwo'
className='accordion-collapse collapse'
aria-labelledby='headingTwo'
data-bs-parent='#accordionExample'
>
<div className='accordion-body'> The amount you pay for motor insurance is called the 
premium. It can vary widely based on factors like your driving history, the type of 
vehicle, your location, and the coverage you select.
</div>
</div>
</div>



<div className='accordion-item'>
<h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseFour' aria-expanded='false'
aria-controls='collapseFour'>
4.	Policy Limits:  </button> </h2>
<div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
These are the maximum amounts your insurance company will pay for covered claims. 
You can choose different limits for various types of coverage.
</div>
</div>
</div>





<div className='accordion-item'>
<h2 className='accordion-header' id='headingSix'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseSix' aria-expanded='false'
aria-controls='collapseSix'>
6.	No-Claims Bonus: </button> </h2>
<div id='collapseSix' className='accordion-collapse collapse' aria-labelledby='headingSix'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
 Some insurance companies offer discounts 
for policyholders who do not make claims for a certain period, rewarding safe driving.
</div>
</div>
</div>


<div className='accordion-item'>
<h2 className='accordion-header' id='headingEight'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseEight' aria-expanded='false'
aria-controls='collapseEight'>
8.	Laws and Regulations:  </button> </h2>
<div id='collapseEight' className='accordion-collapse collapse' aria-labelledby='headingEight'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
Motor insurance is typically regulated by government authorities, and it's essential 
to comply with the legal requirements in your jurisdiction.
</div>
</div>
</div>




<div className='accordion-item'>
<h2 className='accordion-header' id='headingTen'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseTen' aria-expanded='false'
aria-controls='collapseTen'>
10.	Comparing Policies: </button> </h2>
<div id='collapseTen' className='accordion-collapse collapse' aria-labelledby='headingTen'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
 It's wise to compare different insurance 
providers and policies to find the one that best suits your needs and budget.
</div>
</div>
</div>



</div>
</div>

                
<p> Remember that motor insurance is not just a legal requirement but also an essential financial safeguard. It can provide peace of mind knowing that you are protected in case of an accident or unforeseen event involving your vehicle. Always read and understand 
the terms and conditions of your policy to ensure you have the coverage you need. </p>
   
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
  
  </>
  )
}

export default MotorInsuranceDetails