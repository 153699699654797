let serviceList = [
  {
    title: "Health Insurance",
    des: "Health Insurance is an important investment for every individual as it not only secures you and your family from any medical care expense but also ensures that you have access to the best medical facilities like hospitalization expenses",
    img: "https://lelopolicy.com/assets/img/health-insurance.jpg",
  },
  {
    title: "Life Insurance",
    des: "Life insurance is a contract between an individual (the policyholder) and an insurance company. It provides a financial benefit to designated beneficiaries upon the death of the insured person or after a specific period.",
    img: "https://lelopolicy.com/assets/img/life-insurance.jpg",
  },
  {
    title: "Term Insurance",
    des: "Term insurance is a type of life insurance that provides coverage for a specified period, or term, of time. It offers financial protection to the policyholder's beneficiaries in the event of the insured person's death during the term of the policy.",
    img: "https://lelopolicy.com/assets/img/term-insurance.jpg",
  },
  {
    title: "Motor Insurance",
    des: "Motor insurance, also known as auto insurance or car insurance, is a type of insurance policy that provides financial protection against losses incurred due to damage to or theft of a vehicle, as well as liability arising from accidents involving the insured vehicle.",
    img: "https://lelopolicy.com/assets/img/motor-insurance.jpg",
  },
  {
    title: "Travel Insurance",
    des: "Travel insurance is a type of insurance designed to provide financial protection against unexpected events that may occur during a trip. It typically covers a range of potential risks that travelers might face, including medical emergencies, trip cancellations or interruptions ...",
    img: "https://lelopolicy.com/assets/img/travel-insurance.jpg",
  },

  {
    title: "Corporate Insurance",
    des: "Corporate insurance refers to various insurance products and coverage options designed to protect businesses, organizations, and their assets against potential risks and liabilities. These insurance policies are tailored to meet the specific needs and exposures faced by corporations, ",
    img: "https://lelopolicy.com/assets/img/corporate-insurance.jpg",
  },
];

export default serviceList;
