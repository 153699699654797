import React from 'react'
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarTwo from "../components/NavbarTwo";
import MotorInsuranceDetails from '../components/MotorInsuranceDetails';

const MotorInsurance = () => {
  return (
    <>
    
     {/* Navigation Bar */}
     <NavBarTwo />

     {/* Navigation Bar */}
     <Breadcrumb title={" Motor Insurance"} />

     {/* Service Details Area */}
     <MotorInsuranceDetails />

     {/* Footer One */}
     <FooterOne />   
    </>
  )
}

export default MotorInsurance