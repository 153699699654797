import React from 'react'
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarTwo from "../components/NavbarTwo";
import HealthInsuranceDetails from '../components/HealthInsuranceDetails';
const HealthInsurance = () => {
  return (
  <>  
  
     {/* Navigation Bar */}
     <NavBarTwo />

     {/* Navigation Bar */}
     <Breadcrumb title={" Health Insurance"} />

     {/* Service Details Area */}
     <HealthInsuranceDetails />

     {/* Footer One */}
     <FooterOne />

  </>
  )
}

export default HealthInsurance
