import React from 'react'
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarTwo from "../components/NavbarTwo";
import LifeInsuranceDetails from '../components/LifeInsuranceDetails'

const LifeInsurance = () => {
  return (
   <>
   
{/* Navigation Bar */}
<NavBarTwo />

{/* Navigation Bar */}
<Breadcrumb title={"Life Insurance"} />

{/* Service Details Area */}
<LifeInsuranceDetails />

{/* Footer One */}
<FooterOne />

   </>
  )
}

export default LifeInsurance