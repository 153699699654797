import React from 'react'
import {
    FaAngleDoubleRight,
    FaArrowRight,
    FaCheckCircle,
  } from "react-icons/fa";
  import { Link } from "react-router-dom";

const TravelInsuranceDetails = () => {
  return (
    <>
    
   
    <div className='service-area pd-top-120 pd-bottom-90'>
    <div className='container'>
      <div className='row'>
  <div className='col-xl-12 col-lg-12 col-12 '>
          <div className='blog-details-page-content'>
            <div className='single-blog-inner mb-0'>
             <div className='details'>
  <div className="row">
  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
  <h4 className='pt-5'> Travel Insurance </h4>
  <p> Travel insurance is a type of insurance coverage that can help protect you 
  financially while you are traveling. It is designed to cover unexpected expenses and 
  losses that may occur before or during a trip. Travel insurance typically includes a 
  variety of coverage options, and the specific terms and conditions can vary among 
  insurance providers.  </p>
  <p> Here are some common features of travel insurance: </p>
  </div>
  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
  <div className='thumb'>
  <img src='assets/img/service/travel-insurance.jpg' alt='travel-insurance' />
  </div>
  </div>
  </div>
  
  
  <div  className='accordion accordion-inner style-2 accordion-icon-left mt-3 mb-4'
  id='accordionExample'>
  <div className="row">
  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingOne'>
  <button className='accordion-button' type='button' data-bs-toggle='collapse'
  data-bs-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
  1. Trip Cancellation and Interruption:  </button> </h2>
  <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'> 
  This coverage can reimburse you for non-refundable trip expenses if you need to cancel your trip before departure or if your 
  trip is interrupted due to covered reasons, such as illness, injury, or unforeseen events.
  </div>
  </div>
  </div>
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingThree'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseThree' aria-expanded='false'
  aria-controls='collapseThree'>
  3. Baggage and Personal Belongings:   </button> </h2>
  <div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'>
  This coverage can reimburse you for lost, 
  stolen, or damaged luggage and personal items while traveling.
  </div>
  </div>
  </div>
  
  
  
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingFive'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseFive' aria-expanded='false'
  aria-controls='collapseFive'>
  5. Emergency Evacuation and Repatriation:   </button> </h2>
  <div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'> In the event of a medical emergency, this coverage can 
  pay for your evacuation to the nearest suitable medical facility or your return home.
  </div>
  </div>
  </div>
  
  
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingSeven'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseSeven' aria-expanded='false'
  aria-controls='collapseSeven'>
  7. Rental Car Coverage:  </button> </h2>
  <div id='collapseSeven' className='accordion-collapse collapse' aria-labelledby='headingSeven'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'>
  Some policies provide coverage for rental car damage or theft while you're traveling.
  </div>
  </div>
  </div>
  


  </div>
  
  
  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingTwo'>
  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
  data-bs-target='#collapseTwo' aria-expanded='false' aria-controls='collapseTwo'>
  2. Emergency Medical Expenses:  </button> </h2>
  <div
  id='collapseTwo'
  className='accordion-collapse collapse'
  aria-labelledby='headingTwo'
  data-bs-parent='#accordionExample'
  >
  <div className='accordion-body'> Travel insurance can cover medical expenses incurred 
  during your trip, including doctor visits, hospital stays, and emergency medical transportation.
  </div>
  </div>
  </div>
  
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingFour'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseFour' aria-expanded='false'
  aria-controls='collapseFour'>
  4. Travel Delay:  </button> </h2>
  <div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'>
  If your trip is delayed due to circumstances beyond your control (e.g., weather, airline strikes), you may be 
  eligible for compensation to cover additional expenses, such as meals and accommodations.
  </div>
  </div>
  </div>
  
  
  
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingSix'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseSix' aria-expanded='false'
  aria-controls='collapseSix'>
  6. Travel Assistance Services:  </button> </h2>
  <div id='collapseSix' className='accordion-collapse collapse' aria-labelledby='headingSix'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'>
  Travel insurance often includes access to a 24/7 assistance hotline for help with various 
  travel-related issues, including lost passport assistance, translation services, and more.
  </div>
  </div>
  </div>
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingEight'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseEight' aria-expanded='false'
  aria-controls='collapseEight'>
  8. Cancel for Any Reason (CFAR):   </button> </h2>
  <div id='collapseEight' className='accordion-collapse collapse' aria-labelledby='headingEight'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'>
  This is an optional add-on that allows you to cancel your trip for reasons that are not 
  typically covered under standard policies. CFAR coverage typically reimburses a percentage of your prepaid expenses.
  </div>
  </div>
  </div>
  
  
  
  
  <div className='accordion-item'>
  <h2 className='accordion-header' id='headingTen'>
  <button className='accordion-button collapsed' type='button'
  data-bs-toggle='collapse' data-bs-target='#collapseTen' aria-expanded='false'
  aria-controls='collapseTen'>
  10.	Comparing Policies: </button> </h2>
  <div id='collapseTen' className='accordion-collapse collapse' aria-labelledby='headingTen'
  data-bs-parent='#accordionExample' >
  <div className='accordion-body'>
   It's wise to compare different insurance 
  providers and policies to find the one that best suits your needs and budget.
  </div>
  </div>
  </div>
  
  
  
  </div>
  </div>
  
                  
  <p> It's important to carefully review the terms and conditions of any travel insurance 
  policy to understand what is covered and what is excluded. The cost of travel insurance 
  can vary depending on the coverage options you choose, the duration and cost of your trip, 
  your age, and other factors. When purchasing travel insurance, consider 
  your individual needs and the nature of your trip to select the most suitable policy. </p>
  <p> Before purchasing travel insurance, you should also check whether you may already 
  have coverage through other sources, such as your credit card or existing health insurance. 
  Additionally, some countries may  require visitors to have specific types of travel 
  insurance to enter or obtain a visa. </p>
  <p> Keep in mind that travel insurance is designed to provide peace of mind and financial 
  protection in case of unexpected events. It can be particularly valuable when traveling 
  internationally or taking expensive vacations, but whether it's worth it depends on your 
  personal circumstances and the specific trip you're planning. </p>
     
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>


    </>
  )
}

export default TravelInsuranceDetails