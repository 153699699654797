import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const HomeSlider = () => {
  return (
  <>
  
  <Carousel>
  <Carousel.Item>
<img src='https://lelopolicy.com/assets/img/slider/slider1.jpg' className='img-fluid' alt='slider1'  />
  </Carousel.Item>
  <Carousel.Item>
  <img src='https://lelopolicy.com/assets/img/slider/slider2.jpg' className='img-fluid' alt='slider1'  />
  </Carousel.Item>
</Carousel>
  
  </>
  )
}

export default HomeSlider
