import React from "react";

const ContactAreaOne = () => {
  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <div className='contact-area my-5'>
        <div className='container'>
          <div className='contact-inner-1'>
<img className='top_image_bounce animate-img-1' src='assets/img/banner/2.png' alt='img'  />
<img className='top_image_bounce animate-img-2' src='assets/img/about/6.png'  alt='img' />
<div className='row'> 
<div className='col-lg-8' data-aos='fade-right' data-aos-delay='200' data-aos-duration='1500' >
                <img className='w-100' src='assets/img/contact-insurance.jpg' alt='img' />
</div>
<div className='col-lg-4 wow animated fadeInRight' data-aos='fade-left' data-aos-delay='200'
                data-aos-duration='1500' >
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>GET IN TOUCH</h6>
                  <h2 className='title'>
                    Bringing Your <span>Vision</span> To Life
                  </h2>
                  <p className='content'>
                    For your car we will do everything advice design in us
                    repairs and maintenance. We are the some preferred.
                  </p>
                  <form className='mt-4'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Name' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Email' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Phone' />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='single-input-inner style-border'>
                          <input type='text' placeholder='Your Subject' />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                      <div className='single-input-inner style-border'>
                      <select className="form-select py-2">
                      <option selected disabled> Select Insurance </option>
                      <option>  Health Insurance </option>
                      <option>  Travel Insurance </option>
                      <option>  Motor Insurance </option>
                      <option>  Term Insurance </option>
                      <option>  Life Insurance </option>
                      <option>  Bike Insurance </option>
                      <option>  Personal Accident Cover </option>
                      <option> Car Insurance </option>
                      <option> Super Top-up Insurance </option>
                      <option> Property Insurance </option>
                      
                      </select>
                      </div>
                    </div>

                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border'>
                          <textarea placeholder='Message' defaultValue={""} />
                        </div>
                      </div>
                      <div className='col-12'>
                        <a
                          className='btn btn-black mt-0 w-100 border-radius-5'
                          href='#'
                        >
                          Submit now
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default ContactAreaOne;
