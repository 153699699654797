import React from "react";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import serviceList from "../scripts/serviceList";

const ServiceAreaOne = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='section-title text-center'>
<h2 className='title'> Our Exclusive <span> Services </span> </h2>
<p> Insurance typically operates on the principle of risk pooling. Policyholders pay premiums into a pool, and the insurer uses this pool of funds to pay for covered losses and expenses when they occur. Insurance policies are legal contracts that outline the terms and conditions of coverage, including what is covered, the premium amount, deductibles, and other details. There are various types of insurance, including:  </p>

          </div>
          <div className='row pd-bottom-120'>
            {serviceList.slice(0, 6).map((data, index) => (
              <div className='col-lg-4 col-md-6' key={index}>
                <div className='single-service-inner text-center'>
                  <div className='thumb'>
                    <img src={data.img} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                      <Link to='/service-details'>{data.title}</Link>
                    </h5>
                    <p>{data.des}</p>
                    <Link className='btn btn-border-base' to='/service-details'>
                      View More <FaChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaOne;
