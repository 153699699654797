import React from 'react'
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarTwo from "../components/NavbarTwo";
import TravelInsuranceDetails from '../components/TravelInsuranceDetails';


const TravelInsurance = () => {
  return (
   <>
   
      {/* Navigation Bar */}
      <NavBarTwo />

      {/* Navigation Bar */}
      <Breadcrumb title={" Travel Insurance"} />
 
      {/* Service Details Area */}
      <TravelInsuranceDetails />
 
      {/* Footer One */}
      <FooterOne /> 
   
   </>
  )
}

export default TravelInsurance