import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const HealthInsuranceDetails = () => {
  return (
   <>  
     
         <div className='service-area pd-top-120 pd-bottom-90'>
         <div className='container'>
           <div className='row'>
     <div className='col-xl-12 col-lg-12 col-12 '>
               <div className='blog-details-page-content'>
                 <div className='single-blog-inner mb-0'>
                  <div className='details'>
<div className="row">
<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<h4> Health Insurance </h4>
<p>Health insurance is a type of coverage that pays for medical and surgical 
expenses incurred by the insured individual. It helps individuals and 
families manage the high costs of healthcare by covering various medical 
expenses, such as doctor's visits, hospital stays, prescription drugs, 
preventive care, and more. Health insurance can be obtained through private 
insurance companies, government programs, or employer-sponsored plans. </p>
<p> Here are some key points to understand about health insurance: </p>
</div>
<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<div className='thumb'>
<img src='assets/img/service/health-insurance.jpg' alt='img' />
</div>
</div>
</div>


 <div  className='accordion accordion-inner style-2 accordion-icon-left mt-3 mb-4'
id='accordionExample'>
<div className="row">
<div className="col-xl-6 col-lg-6 col-md-6 col-12">
<div className='accordion-item'>
<h2 className='accordion-header' id='headingOne'>
<button className='accordion-button' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
1.	Premium:  </button> </h2>
<div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
data-bs-parent='#accordionExample' >
<div className='accordion-body'> This is the amount you pay for your health insurance coverage, typically on a monthly basis.
  </div>
</div>
</div>


<div className='accordion-item'>
<h2 className='accordion-header' id='headingThree'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseThree' aria-expanded='false'
aria-controls='collapseThree'>
3.	Co-Pay:  </button> </h2>
<div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
A co-pay is a fixed amount you pay for a specific service or prescription. For example, 
you might pay a $20 co-pay for a doctor's office visit.
  </div>
</div>
</div>





<div className='accordion-item'>
<h2 className='accordion-header' id='headingFive'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseFive' aria-expanded='false'
aria-controls='collapseFive'>
5.	Out-of-Pocket Maximum:   </button> </h2>
<div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive'
data-bs-parent='#accordionExample' >
<div className='accordion-body'> This is the maximum amount you'll have to pay for covered services in a plan year. Once you reach this limit, the insurance company pays 100% of covered services.
  </div>
</div>
</div>




<div className='accordion-item'>
<h2 className='accordion-header' id='headingSeven'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseSeven' aria-expanded='false'
aria-controls='collapseSeven'>
7.	Preventive Care:   </button> </h2>
<div id='collapseSeven' className='accordion-collapse collapse' aria-labelledby='headingSeven'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
Many health insurance plans cover preventive services like 
vaccinations and screenings at no cost to the insured.
  </div>
</div>
</div>


<div className='accordion-item'>
<h2 className='accordion-header' id='headingNine'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseNine' aria-expanded='false'
aria-controls='collapseNine'>
9.	Prescription Drugs:   </button> </h2>
<div id='collapseNine' className='accordion-collapse collapse' aria-labelledby='headingNine'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
Most health insurance plans offer some coverage for prescription medications. The specific 
drugs covered and the cost-sharing varies.
  </div>
</div>
</div>



<div className='accordion-item'>
<h2 className='accordion-header' id='headingEleven'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseEleven' aria-expanded='false'
aria-controls='collapseEleven'>
11.	Healthcare Marketplace (Exchange):  </button> </h2>
<div id='collapseEleven' className='accordion-collapse collapse' aria-labelledby='headingEleven'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
In some countries, there are healthcare marketplaces where individuals and families can purchase insurance 
plans, often with government subsidies.
  </div>
</div>
</div>








</div>


<div className="col-xl-6 col-lg-6 col-md-6 col-12">


<div className='accordion-item'>
<h2 className='accordion-header' id='headingTwo'>
<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'
data-bs-target='#collapseTwo' aria-expanded='false' aria-controls='collapseTwo'>
2.	Deductible </button> </h2>
<div
  id='collapseTwo'
  className='accordion-collapse collapse'
  aria-labelledby='headingTwo'
  data-bs-parent='#accordionExample'
>
<div className='accordion-body'> This is the amount you must pay out of pocket for covered healthcare services before your insurance plan starts to pay.
  </div>
</div>
</div>



<div className='accordion-item'>
<h2 className='accordion-header' id='headingFour'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseFour' aria-expanded='false'
aria-controls='collapseFour'>
4.	Co-Insurance:   </button> </h2>
<div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
This is a percentage of the cost of a covered healthcare service that you are responsible for paying. For instance, you might be responsible for 20% of the cost of a hospital stay.
  </div>
</div>
</div>





<div className='accordion-item'>
<h2 className='accordion-header' id='headingSix'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseSix' aria-expanded='false'
aria-controls='collapseSix'>
6.	Network:   </button> </h2>
<div id='collapseSix' className='accordion-collapse collapse' aria-labelledby='headingSix'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
Many health insurance plans have a network of preferred doctors, hospitals, and healthcare providers. Staying within 
the network can often reduce your out-of-pocket costs.
  </div>
</div>
</div>


<div className='accordion-item'>
<h2 className='accordion-header' id='headingEight'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseEight' aria-expanded='false'
aria-controls='collapseEight'>
8.	Emergency Care:   </button> </h2>
<div id='collapseEight' className='accordion-collapse collapse' aria-labelledby='headingEight'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
Health insurance usually covers emergency medical care, even if it's 
out of your network.
  </div>
</div>
</div>




<div className='accordion-item'>
<h2 className='accordion-header' id='headingTen'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseTen' aria-expanded='false'
aria-controls='collapseTen'>
10.	Types of Health Insurance:   </button> </h2>
<div id='collapseTen' className='accordion-collapse collapse' aria-labelledby='headingTen'
data-bs-parent='#accordionExample' >
<div className='accordion-body'>
There are various types of health insurance, including employer-sponsored plans, individual plans, Medicare for 
seniors, and Medicaid for low-income individuals.
  </div>
</div>
</div>




<div className='accordion-item'>
       <h2 className='accordion-header' id='headingTwelve'>
<button className='accordion-button collapsed' type='button'
data-bs-toggle='collapse' data-bs-target='#collapseTwelve' aria-expanded='false'
aria-controls='collapseTwelve'>
12.	Government Programs:  </button> </h2>
<div id='collapseTwelve' className='accordion-collapse collapse' aria-labelledby='headingTwelve'
data-bs-parent='#accordionExample' >
<div className='accordion-body'> Some countries have government-run healthcare systems that provide healthcare coverage to all residents. In the United States, for example, there's 
Medicare for seniors and Medicaid for low-income individuals.
         </div>
       </div>
     </div>
</div>
</div>

                       
 <p> Health insurance is essential for protecting your financial well-being in case of unexpected medical expenses. It's important to carefully read and understand your policy, including its coverage, costs, and network of healthcare providers, to make informed decisions about your healthcare. Additionally, regular check-ups, preventive care, and a healthy lifestyle can help you 
 make the most of your health insurance coverage and maintain your overall well-being.
                     </p>
                     <div className='row'>
                       <div className='col-md-6'>
                         <ul className='single-list-inner style-check style-check mb-3'>
                           <li>
                             <FaCheckCircle /> Creating a Balanced and Nutritious
                           </li>
                           <li>
                             <FaCheckCircle /> iTechnology that helps grow
                             companies
                           </li>
                           <li>
                             <FaCheckCircle /> Everything you need to succeed
                           </li>
                         </ul>
                       </div>
                       <div className='col-md-6'>
                         <ul className='single-list-inner style-check style-check mb-3'>
                           <li>
                             <FaCheckCircle /> Creating a Balanced and Nutritious
                           </li>
                           <li>
                             <FaCheckCircle /> iTechnology that helps grow
                             companies
                           </li>
                           <li>
                             <FaCheckCircle /> Everything you need to succeed
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
 
   
   </>
  )
}

export default HealthInsuranceDetails
